import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import api, { ConfigurationPackage, FlowAssembly, Model, SubmitExternalQuoteResponse } from "../../api";
import { Loadable } from "../loading";
import ModelSelector from "../model-selector";
import OptionsSelector from "../options-selector";
import OrderFinishedPage from "../order-finished";
import OrderSubmission, { CustomerDetailsFormData } from "../order-submission";

enum WizardStep {
    ModelSelection,
    OptionsSelection,
    Submit
}

const STEPS = [
    WizardStep.ModelSelection,
    WizardStep.OptionsSelection,
    WizardStep.Submit
];

function isLastStep(step: WizardStep) {
    return STEPS.indexOf(step) == STEPS.length - 1;
}

function isFirstStep(step: WizardStep) {
    return STEPS.indexOf(step) == 0;
}

const ConfigurationWizard = (props: any) => {
    const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.ModelSelection);
    const [currentModel, setCurrentModel] = useState<Model | undefined>(undefined);
    const [models, setModels] = useState<Model[]>([]);

    const [selectedOptions, setSelectedOptions] = useState<Record<number, FlowAssembly[]>>({});
    const [selectedPackages, setSelectedPackages] = useState<Record<number, ConfigurationPackage[]>>({});

    const [customerData, setCustomerData] = useState<CustomerDetailsFormData>();

    const [orderFinishedResponse, setOrderFinishedResponse] = useState<SubmitExternalQuoteResponse>();

    const [params, setParams] = useSearchParams();

    useEffect(() => {
        const step = params.get('step');
        if (step) {
            try {
                const n = parseInt(new Number(step).toString());
                if (n < STEPS.length) {
                    setCurrentStep(STEPS[n]);
                }
            }
            catch (e) { }
        }
    }, [params]);

    useEffect(() => {
        if (models.length > 0) {
            try {
                const modelId = params.get('model');
                if (modelId) {
                    const n = new Number(modelId);
                    const existingModel = models.find(m => m.id == n);
                    if (existingModel) {
                        setCurrentModel(existingModel);
                    }
                }
            }
            catch (e) { }
        }
    }, [models]);

    async function init() {
        const models = await api.getModels();
        setModels(models);
    }

    function onSelectModel(model: Model | undefined) {
        setCurrentModel(model);
    }

    function nextStep() {
        setCurrentStep(STEPS[STEPS.indexOf(currentStep) + 1]);
        params.set('step', (currentStep + 1).toString());
        setParams(params);
    }

    function previousStep() {
        setCurrentStep(STEPS[STEPS.indexOf(currentStep) - 1]);
        params.set('step', (currentStep - 1).toString());
        setParams(params);
    }

    function onFinishSubmitOrder(response: SubmitExternalQuoteResponse) {
        setOrderFinishedResponse(response);
    }

    async function onSubmitOrder(customerData: CustomerDetailsFormData) {
        if (!currentModel) throw 'Missing model';

        const request = {
            customerData: customerData,
            executeFlowRequest: {
                modelId: currentModel.id,
                selectedOptions: Object.values(selectedOptions).flatMap(fa => fa.map(asm => asm.id)),
                selectedPackages: Object.values(selectedPackages).flatMap(pkgs => pkgs.map(pkg => pkg.id)),
            }
        };

        return await api.submitOrder(currentModel.configurationFlowId, request);
    }

    function getCurrentStepView(): React.ReactElement {
        switch (currentStep) {
            case WizardStep.ModelSelection:
                return (
                    <ModelSelector models={models} onSelectModel={onSelectModel} selectedModel={currentModel} />
                );
            case WizardStep.OptionsSelection:
                return (
                    <OptionsSelector model={currentModel} selectedOptions={selectedOptions} selectedPackages={selectedPackages}
                        onOptionsUpdated={setSelectedOptions} onPackagesUpdated={setSelectedPackages} />
                );
            default:
                return (<div></div>);
        };
    }

    const stepView = getCurrentStepView();

    return (
        <Loadable load={init}>
            <div className="flex flex-col items-center gap-4 w-full m-4">
                {orderFinishedResponse ?
                    <OrderFinishedPage orderFinishedResponse={orderFinishedResponse} selectedOptions={selectedOptions} selectedPackages={selectedPackages} model={currentModel} />
                    :
                    <>
                        {stepView}
                        <OrderSubmission visible={currentStep == WizardStep.Submit} model={currentModel} selectedOptions={selectedOptions} selectedPackages={selectedPackages}
                            onSubmitOrder={onSubmitOrder} onFinishSubmitOrder={onFinishSubmitOrder} />
                        <div className="flex flex-row justify-center gap-2">
                            {!isFirstStep(currentStep) ?
                                <Button onClick={previousStep} className="w-1" variant="contained">Back</Button> : ''}
                            {!isLastStep(currentStep) ?
                                <Button disabled={!currentModel} onClick={nextStep} className="w-1" variant="contained">Next</Button> : ''}
                        </div>
                    </>}
            </div>
        </Loadable>
    )
};


export default ConfigurationWizard;
