import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api, { Model } from "../../api";
import { Loadable } from "../loading";

import modelImage from '../../assets/model.webp';
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface ModelCardProps {
    model: Model
    onClickModel: (model: Model) => void;
    selected: boolean;
}
const ModelCard = (props: ModelCardProps) => {
    return (
        <Card onClick={() => props.onClickModel(props.model)} variant="outlined" className={"sm:w-full md:w-1/4 xl:w-1/6 hover:bg-gray-200 hover:cursor-pointer"}>
            <CardMedia
                component="img"
                image={modelImage}
                alt={props.model.name + ' image'}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" className="uppercase">
                    {props.model.name}
                    {props.selected ? <CheckCircleOutline/> : ''}
                </Typography>
            </CardContent>
        </Card>
    );
};

export interface ModelSelectorProps {
    onSelectModel?: (model: Model|undefined) => void;
    selectedModel?: Model;
    models: Model[];
}

const ModelSelector = (props: ModelSelectorProps) => {
    const [selectedModel, setSelectedModel] = useState<Model|undefined>(props.selectedModel);

    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!props.models) return;
        try {
            const modelId = params.get('model');
            if(modelId) {
                const n = new Number(modelId);
                const existingModel = props.models.find(m => m.id == n);
                if(existingModel) {
                    selectModel(existingModel);
                }
            }
        }
        catch(e) {}
    }, [props.models]);

    function selectModel(model: Model) {
        const newModel = selectedModel == model ? undefined : model;
        setSelectedModel(newModel);
        if(props.onSelectModel) props.onSelectModel(newModel);

        if(newModel) {
            params.set('model', newModel ? newModel.id.toString() : '');
        }
        else {
            params.delete('model');
        }
        setParams(params);
    }

    return (
            <div className="flex flex-col md:w-3/4">
                <div className="flex flex-row justify-center m-3">
                    <h1>Select your Battle Motors Model</h1>
                </div>
                <div className="flex flex-row flex-wrap justify-center gap-2">
                    {props.models.map(model => (
                        <ModelCard onClickModel={(model) => selectModel(model)} selected={model.id == selectedModel?.id} key={model.id} model={model} />
                    ))}
                </div>
            </div>
    );
};

export default ModelSelector;
