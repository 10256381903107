import { CircularProgress } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

export type LoadFunction = () => Promise<void>;
enum LoadState {
    Loading,
    Finished,
    Error
}

export const Loading = () => {
    return (<CircularProgress />);
};

export const LoadError = () => {
    return (<p>Failed to load at this time.</p>);
};

interface LoadStateProps {
    load: LoadFunction
    children: React.ReactNode
}
export const Loadable = (props: LoadStateProps) => {
    const [state, setState] = useState<LoadState>(LoadState.Loading);

    useEffect(() => {
        (async function () {
            try {
                await props.load();
                setState(LoadState.Finished);
            }
            catch (e) {
                console.log('err: ', e);
                setState(LoadState.Error);
            }
        })();
    }, []);

    if (state == LoadState.Loading) {
        return (<Loading />);
    }
    else if (state == LoadState.Error) {
        return (<LoadError />);
    }
    else {
        return (
            <>
                {props.children}
            </>
        );
    }
};