import { Button } from "@mui/material";
import { ConfigurationPackage, FlowAssembly, Model, SubmitExternalQuoteResponse } from "../api"
import SelectedOptions from "./selected-options";
import SelectedPackages from "./selected-packages";

export interface Props {
    orderFinishedResponse: SubmitExternalQuoteResponse;
    model?: Model;
    selectedOptions: Record<number, FlowAssembly[]>;
    selectedPackages: Record<number, ConfigurationPackage[]>;
}

const OrderFinishedPage = (props: Props) => {
    return (
        <div>
            <h1>Order Submitted</h1>
            <div className="mt-4 flex flex-col gap-4">
                <p>Thanks for submitting your order.</p>
                <p>Your order number reference is <strong>{props.orderFinishedResponse.quoteId}</strong>. A Battle Motors sales representative will reach out to you soon!</p>
            </div>
            <div className="mt-5">
                <a target="_new" href={props.orderFinishedResponse.quotePdfUrl}>Download PDF</a>
            </div>

            <div className="mt-5">
                <h2>Model</h2>
                <p>{props.model?.name}</p>
            </div>
            <SelectedOptions options={props.selectedOptions} />
            <SelectedPackages packages={props.selectedPackages} />
        </div>
    )
};

export default OrderFinishedPage;