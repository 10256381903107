import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import ConfigurationWizard from './components/configuration-wizard';
import BattleLogo from './assets/battle_logo.png';
import api, { UserInfo } from './api';
import { CONFIGURATOR_URL } from './config';

export interface AppContextData {
  user?: UserInfo;
}

export const AppContext = React.createContext<AppContextData>({});

function App() {
  const [appContextValue, setAppContextDataValue] = useState<AppContextData>({});

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {
      const userInfo = await api.userInfo();
      setAppContextDataValue({...appContextValue, user: userInfo});
    }
    catch (e) { }
  }

  async function onLogin() {
    window.location.href = CONFIGURATOR_URL;
  }

  return (
    <AppContext.Provider value={appContextValue}>
    <div>
      <div className="top-nav-header flex flex-row items-center bg-black h-16 pl-8">
        <div className="w-1/2">
          <a href="/"><img className="h-12" src={BattleLogo} /></a>
        </div>
        <div className="w-1/2 flex flex-row justify-end text-white mr-8">
          <div>
            {appContextValue.user ?
              <div>
                Logged in as <span className="font-bold">{appContextValue.user?.name}</span>
              </div> :
              <div>
                <a href="#" onClick={onLogin}>Battle Motors Employee Login</a>
              </div>}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center h-16 pl-8" style={{ backgroundColor: '#3DB7E4', color: 'white' }}>
        <h2>Build Your Own Truck</h2>
      </div>
      <div className="flex justify-center m-1">
        <Routes>
          <Route path="/" element={<ConfigurationWizard />} />
          <Route path="*" element={<div>not found</div>} />
        </Routes>
      </div>
    </div>
    </AppContext.Provider>
  );
}

export default App;
