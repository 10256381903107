import { ConfigurationPackage } from "../api";

const SelectedPackages = (props: { packages: Record<number, ConfigurationPackage[]> }) => {
    const packages = Object.entries(props.packages).flatMap(([k, v]) => v);

    return (
        <div className="mt-5">
            <h2>Selected Packages</h2>
            {packages.length == 0 ?
                <div>None</div> :
                <ul className="list-disc list-inside text-left mt-2">
                    {packages.map(pkg => (
                        <li key={pkg.id}>{pkg.name}</li>
                    ))}
                </ul>}
        </div>
    );
};

export default SelectedPackages;