import { Card, CardContent, CardMedia, CircularProgress, Divider, stepperClasses, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api, { ConfigurationFlow, ConfigurationFlowStep, ConfigurationPackage, FlowAssembly, Model } from "../../api";
import { Loadable, Loading } from "../loading";

import modelImage from '../../assets/model.webp';
import questionImage from '../../assets/question.png';
import { CheckCircleOutline, CheckCircleOutlined, TurnedIn } from "@mui/icons-material";

interface FlowStepProps {
    step: ConfigurationFlowStep;
    selectedOptions: Record<number, FlowAssembly[]>;
    selectedPackages: Record<number, ConfigurationPackage[]>;
    onSelectOption?: (step: ConfigurationFlowStep, option: FlowAssembly) => void;
    onSelectPackage?: (step: ConfigurationFlowStep, pkg: ConfigurationPackage) => void;
    loading: boolean;
    previousStepComplete: boolean;
}

export interface ConfigurableOption {
    name: string;
    description?: string;
    fullImageUrl: string;
    thumbnailImageUrl: string;
}

export interface StepOptionProps {
    option: ConfigurableOption;
    selected: boolean;
    package: boolean;
    onSelect?: () => void;
}

const StepOption = (props: StepOptionProps) => {
    return (
        <div onClick={props.onSelect} className="flex flex-row justify-start items-center border gap-1 border-gray-400 hover:bg-gray-100">
            <span className="w-3/12">
                <img src={props.option.thumbnailImageUrl ? props.option.thumbnailImageUrl : modelImage} />
            </span>
            {props.package && props.option.description && props.option.description.length > 0 ?
                <div className="flex flex-col w-8/12">
                    <span>{props.option.name}</span>
                    <span className="text-sm">{props.option.description}</span>
                </div>
                :
                <span className="w-8/12">{props.option.name}</span>
            }
            <span className="w-1/12">{props.selected ? <CheckCircleOutlined /> : ''}</span>
        </div>
    );
}

const NoSelectionBox = (props: FlowStepProps) => {
    const hasSelection = props.selectedOptions[props.step.id];
    const selectedOptions = hasSelection ? props.selectedOptions[props.step.id].map(opt => opt.name)
        : ['Select an option'];
    return (
        <div className="w-full bg-gray-300 flex flex-row justify-center items-center">
            <span>{selectedOptions.join(', ')}</span>
        </div>
    );
};


const OptionImage = (props: FlowStepProps) => {
    var image = null;
    if (props.selectedOptions[props.step.id]) {
        for (const item of props.selectedOptions[props.step.id]) {
            if (item.fullImageUrl && item.fullImageUrl.length > 0) {
                image = item.fullImageUrl;
                break;
            }
        }
    }

    if (props.selectedPackages[props.step.id]) {
        for (const item of props.selectedPackages[props.step.id]) {
            if (item.fullImageUrl && item.fullImageUrl.length > 0) {
                image = item.fullImageUrl;
                break;
            }
        }
    }

    if (image != null) {
        return (
            <div style={{
                backgroundSize: 'contain', backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat', backgroundImage: `url(${image})`
            }} className="w-full flex flex-row justify-center items-center">
            </div>
        );
    }
    else {
        return <NoSelectionBox {...props} />;
    }
};

const FlowStep = (props: FlowStepProps) => {
    return (
        <div className="flex flex-row lg:w-4/5 xl:w-4/6 gap-8 h-96">
            <div className="flex flex-row justify-center w-1/2 shrink-0">
                <OptionImage {...props} />
            </div>
            <div className="flex flex-col gap-1 w-1/2 shrink-0">
                <h1>{props.step.title}</h1>
                <div className={"flex flex-col overflow-auto gap-1 " + (props.loading ? 'bg-gray-200' : '')}>
                    {props.previousStepComplete ?
                        <>
                            {props.step.options.sort((a, b) => (a.name.localeCompare(b.name))).map(option => (
                                <StepOption key={option.id}
                                    onSelect={() => props.onSelectOption ? props.onSelectOption(props.step, option) : {}}
                                    package={false}
                                    option={option} selected={!!props.selectedOptions[props.step.id]?.find(o => o.id == option.id)} />
                            ))}
                            {props.step.packages.sort((a, b) => (a.name.localeCompare(b.name))).map(option => (
                                <StepOption key={option.id}
                                    onSelect={() => props.onSelectPackage ? props.onSelectPackage(props.step, option) : {}}
                                    package={true}
                                    option={option} selected={!!props.selectedPackages[props.step.id]?.find(o => o.id == option.id)} />
                            ))}
                        </> : <div className="flex w-full">Please make a selection from the previous step</div>}
                </div>
            </div>
        </div>
    );
};


export interface OptionsSelectorProps {
    model: Model | undefined;
    selectedOptions: Record<number, FlowAssembly[]>;
    selectedPackages: Record<number, ConfigurationPackage[]>;
    onOptionsUpdated?: (options: Record<number, FlowAssembly[]>) => void;
    onPackagesUpdated?: (options: Record<number, ConfigurationPackage[]>) => void;
}

const OptionsSelector = (props: OptionsSelectorProps) => {
    const [flow, setFlow] = useState<ConfigurationFlow | undefined>(undefined);
    const [selectedOptions, setSelectedOptions] = useState<Record<number, FlowAssembly[]>>(props.selectedOptions);
    const [selectedPackages, setSelectedPackages] = useState<Record<number, ConfigurationPackage[]>>(props.selectedPackages);
    const [loading, setLoading] = useState<boolean>(true);

    async function init() {
        if (!props.model) return;
        try {
            setLoading(true);
            const response = await api.executeFlow(props.model.configurationFlowId, {
                modelId: props.model?.id || 0,
                selectedOptions: [],
                selectedPackages: [],
            });

            setFlow(response.flow);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (props.onOptionsUpdated) props.onOptionsUpdated(selectedOptions);
    }, [selectedOptions]);

    useEffect(() => {
        if (props.onPackagesUpdated) props.onPackagesUpdated(selectedPackages);
    }, [selectedPackages]);

    useEffect(() => {
        init();
    }, [props.model]);

    async function executeFlow(newPackages: Record<number, ConfigurationPackage[]>, newOptions: Record<number, FlowAssembly[]>) {
        if(!props.model) return;

        const options: number[] = [];
        for (var category in newOptions) {
            for (var x = 0; x < newOptions[category].length; x++) {
                options.push(newOptions[category][x].id);
            }
        }

        const packages: number[] = [];
        for (var category in newPackages) {
            for (var x = 0; x < newPackages[category].length; x++) {
                packages.push(newPackages[category][x].id);
            }
        }

        try {
            setLoading(true);
            const response = await api.executeFlow(props.model.configurationFlowId, {
                modelId: props.model.id,
                selectedOptions: options,
                selectedPackages: packages,
            });

            setFlow(response.flow);

            const newStepOptions: Record<number, FlowAssembly[]> = {};

            response.flow.steps.forEach(step => {
                const selectedStepOptions = newOptions[step.id];
                if (selectedStepOptions) {
                    newStepOptions[step.id] = selectedStepOptions.filter(so => {
                        return step.options.find(opt => opt.id == so.id);
                        /*if(!step.options.find(opt => opt.id == so.id)) {
                            console.log('we lost an option: ' + so);
                        }*/
                    });
                }
            });

            setSelectedOptions(newStepOptions);

            const newStepPackages: Record<number, ConfigurationPackage[]> = {};
            response.flow.steps.forEach(step => {
                const selectedStepPackages = newPackages[step.id];
                if (selectedStepPackages) {
                    newStepPackages[step.id] = selectedStepPackages.filter(sp => {
                        return step.packages.find(pkg => pkg.id == sp.id);
                        /*if(!step.packages.find(pkg => pkg.id == sp.id)) {
                            console.log('we lost a package: ' + sp.name);
                        }*/
                    });
                }
            });
            setSelectedPackages(newStepPackages);
        } finally {
            setLoading(false);
        }
    }

    async function onSelectPackage(step: ConfigurationFlowStep, pkg: ConfigurationPackage) {
        // no multi-select yet
        //const options = selectedOptions[step.id] || [];

        const newSelectedPackages = { ...selectedPackages, [step.id]: [pkg] };
        setSelectedPackages(newSelectedPackages);
        executeFlow(newSelectedPackages, selectedOptions);
    }

    async function onSelectOption(step: ConfigurationFlowStep, option: FlowAssembly) {
        // no multi-select yet
        //const options = selectedOptions[step.id] || [];
        const newSelectedOptions = { ...selectedOptions, [step.id]: [option] };
        setSelectedOptions(newSelectedOptions);
        executeFlow(selectedPackages, newSelectedOptions);
    }

    function isPreviousStepComplete(index: number) {
        if (index == 0) return true;
        if (selectedOptions[steps[index - 1].id]?.length > 0) return true;
        if (selectedPackages[steps[index - 1].id]?.length > 0) return true;
        return false;
    }

    const steps = flow ? flow.steps.sort((a, b) => a.order - b.order) : [];

    return (
        <div className="flex flex-col gap-4 grow-0 shrink-0 w-full">
            {<div className="flex flex-col content-center m-3 text-center">
                <h1>Select Options</h1>
                <h2>{props.model?.name}</h2>
            </div>}
            {<div className="flex flex-col items-center gap-16">
                <Loadable load={init}>
                    {flow ?
                        steps.map((step, index) => (
                            <FlowStep
                                key={step.id}
                                onSelectOption={onSelectOption}
                                onSelectPackage={onSelectPackage}
                                loading={loading}
                                previousStepComplete={isPreviousStepComplete(index)}
                                step={step} selectedOptions={selectedOptions} selectedPackages={selectedPackages} />
                        )) : ''}
                </Loadable>
            </div>}
        </div>
    );
};

export default OptionsSelector;
