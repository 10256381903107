import { CustomerDetailsFormData } from "../components/order-submission";

const API_URL: string|undefined = process.env.REACT_APP_API_URL;

export interface Model {
    id: number;
    name: string;
    configurationFlowId: string;
}

export interface FlowAssembly {
    id: number;
    name: string;
    description?: string;
    fullImageUrl: string;
    thumbnailImageUrl: string;
}

export interface ConfigurationPackage {
    id: number;
    name: string;
    description: string;
    fullImageUrl: string;
    thumbnailImageUrl: string;
}

export interface ConfigurationFlowStep {
    id: number;
    title: string;
    description: string;
    order: number;
    categoryName: string;
    packages: ConfigurationPackage[];
    options: FlowAssembly[];
}

export interface ConfigurationFlow {
    name: string;
    steps: ConfigurationFlowStep[];
}

export interface ExecuteFlowRequest {
    modelId: number;
    selectedOptions: number[];
    selectedPackages: number[];
}

export interface CustomerData {
    firstName: string;
    lastName: string;
    companyName?: string;
    address1: string;
    address2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    emailAddress: string;
}

export interface ExecuteFlowResponse {
    flow: ConfigurationFlow;
}

export interface SubmitExternalQuoteRequest {
    customerData: CustomerData;
    executeFlowRequest: ExecuteFlowRequest;
}

export interface SubmitExternalQuoteResponse {
    quoteId: string;
    quotePdfUrl: string;
}

class APIError {
    private error: any;

    constructor(error: any) {
        this.error = error;
    }
}

export interface UserInfo {
    name: string;
    email: string;
}

class APIClient {
    private apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    public async userInfo(): Promise<UserInfo> {
        const resp = await fetch(this.apiUrl + '/v1/user/info', {
            credentials: 'include'
        });
        return resp.json();
    }

    public async submitOrder(flowId: string, request: SubmitExternalQuoteRequest): Promise<SubmitExternalQuoteResponse> {
        const resp = await fetch(this.apiUrl + '/v1/configuration/flow/' + encodeURIComponent(flowId) + '/order', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
        });
        if(resp.status != 200) {
            throw new APIError(resp);
        }
        return await resp.json();
    }

    public async executeFlow(flowId: string, request: ExecuteFlowRequest): Promise<ExecuteFlowResponse> {
        const resp = await fetch(this.apiUrl + '/v1/configuration/flow/' + encodeURIComponent(flowId), {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
        });
        if(resp.status != 200) {
            throw new APIError(resp);
        }
        return await resp.json();
    }

    public async getModels(): Promise<Model[]> {
        const resp = await fetch(this.apiUrl + '/v1/configuration/models');
        if(resp.status != 200) {
            throw new APIError(resp);
        }
        return await resp.json();
    }
}

export default new APIClient(API_URL||'');
