import { FlowAssembly } from "../api";

const SelectedOptions = (props: { options: Record<number, FlowAssembly[]> }) => {
    const options = Object.entries(props.options).flatMap(([k, v]) => v);

    return (
        <div className="mt-5">
            <h2>Selected Options</h2>
            {options.length == 0 ?
                <div>None</div> :
                <ul className="list-disc list-inside mt-2">
                    {options.map(opt => (
                        <li key={opt.id}>{opt.name}</li>
                    ))}
                </ul>}
        </div>
    );
};

export default SelectedOptions;