import { Alert, Button, TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ConfigurationPackage, FlowAssembly, Model, SubmitExternalQuoteResponse } from "../../api";
import SelectedOptions from "../selected-options";
import SelectedPackages from "../selected-packages";
import { AppContext } from "../../App";
import { CONFIGURATOR_URL } from "../../config";

export interface Props {
    visible: boolean;
    model?: Model;
    selectedOptions: Record<number, FlowAssembly[]>;
    selectedPackages: Record<number, ConfigurationPackage[]>;
    customerData?: CustomerDetailsFormData;
    onCustomerDataChange?: (customerData: CustomerDetailsFormData) => void;
    onSubmitOrder?: (customerData: CustomerDetailsFormData) => Promise<SubmitExternalQuoteResponse>;
    onFinishSubmitOrder?: (response: SubmitExternalQuoteResponse) => void;
}

export interface CustomerDetailsFormData {
    firstName: string;
    lastName: string;
    companyName?: string;
    address1: string;
    address2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    emailAddress: string;
}

interface CustomerDetailsFormProps {
    onSubmit?: (formData: CustomerDetailsFormData) => Promise<SubmitExternalQuoteResponse>;
    customerData?: CustomerDetailsFormData;
    onFinish?: (response: SubmitExternalQuoteResponse) => void;
}

const CustomerDetailsForm = (props: CustomerDetailsFormProps) => {
    const { register, handleSubmit, control, watch, formState: { errors } } = useForm({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string|undefined>();

    const onSubmit = async (data: any) => {
        try {
            setErrorMessage(undefined);
            setLoading(true);
            if(props.onSubmit) {
                const response = await props.onSubmit((data as CustomerDetailsFormData));
                if(props.onFinish) props.onFinish(response);
            }
        }
        catch(e) {
            setErrorMessage('Failed to submit your order at this time.');
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
                <h2>Customer Information</h2>
                <div className="w-full grid grid-cols-1 gap-4">
                    <TextField required label="Email Address" {...register("emailAddress")} />
                </div>
                <div className="w-full grid grid-cols-1 gap-4">
                    <TextField required label="Company Name" {...register("companyName")} />
                </div>
                <div className="w-full grid grid-cols-2 gap-4">
                    <TextField required label="First Name" {...register("firstName")} />
                    <TextField required label="Last Name" {...register("lastName")} />
                </div>
                <div className="w-full grid grid-cols-1 gap-4">
                    <TextField required label="Address Line 1" {...register("address1")} />
                    <TextField label="Address Line 2" {...register("address2")} />
                </div>
                <div className="w-full grid grid-cols-3 gap-4">
                    <TextField required label="City" {...register("city")} />
                    <TextField required label="State" {...register("stateProvince")} />
                    <TextField required label="Postal Code" {...register("postalCode")} />
                </div>
            </div>
                <div className="m-4"></div>
                {errorMessage ? <Alert className="mb-2" severity="error">{errorMessage}</Alert> : ''}
                <LoadingButton loading={loading} type="submit" variant="contained">Submit Order</LoadingButton>
        </form>
    );
};

const OrderSubmission = (props: Props) => {
    const appContext = useContext(AppContext);
    const [customerData, setCustomerData] = useState<CustomerDetailsFormData>();

    async function onFinishSubmitOrder(response: SubmitExternalQuoteResponse) {
        if(appContext.user) {
            window.location.href = CONFIGURATOR_URL + '/configurator/' + encodeURIComponent(response.quoteId);
        }
        else {
            if(props.onFinishSubmitOrder) props.onFinishSubmitOrder(response);
        }
    }

    return (
        <div className={"flex flex-col items-center justify-center text-center w-full xl:w-4/5 2xl:w-2/5 " + (!props.visible ? 'hidden' : '')}>
            <h1>Order Summary</h1>
            <p>Please review your order summary below, fill out the form, and hit submit.</p>
            <div className="mt-5">
                <h2>Model</h2>
                <p>{props.model?.name}</p>
            </div>
            <SelectedOptions options={props.selectedOptions} />
            <SelectedPackages packages={props.selectedPackages} />

            <div className="mt-5 w-full xl:w-4/5 grid grid-cols-1 gap-2">
                <CustomerDetailsForm customerData={customerData} onSubmit={props.onSubmitOrder} onFinish={onFinishSubmitOrder} />
            </div>
        </div>
    );
};

export default OrderSubmission;